import * as goog from '../../closure/goog/goog.js';
goog.declareModuleId('Blockly.Arduino.modules');

import {arduinoGenerator as Arduino} from '../arduino.js';
import {Hardware} from '../../core/hardware.js';

/**************************************************
 * BLOCKS for DHT22 Module
 **************************************************/
Arduino['dht22-get-temperature'] = function(block){
  let aModName = block.getFieldValue("ModuleName");

  for (const eMod of Hardware.usedModules)
  {
    //if module type and name are the same with the block
    if ((eMod[0] == 'DHT22') && (eMod[1] == aModName))
    {
      let code = eMod[2] + '.getTemperature()';
      return [code, Arduino.ORDER_ATOMIC];
    }
  }
  return '';
};

Arduino['dht22-get-humidity'] = function(block){
  let aModName = block.getFieldValue("ModuleName");

  for (const eMod of Hardware.usedModules)
  {
    //if module type and name are the same with the block
    if ((eMod[0] == 'DHT22') && (eMod[1] == aModName))
    {
      let code = eMod[2] + '.getHumidity()';
      return [code, Arduino.ORDER_ATOMIC];
    }
  }
  return '';
};

/**************************************************
 * BLOCKS for DHT22 Module
 **************************************************/
Arduino['soil-moisture'] = function(block){
  let aModName = block.getFieldValue("ModuleName");

  for (const eMod of Hardware.usedModules)
  {
    //if module type and name are the same with the block
    if ((eMod[0] == 'SOIL-MOISTURE') && (eMod[1] == aModName))
    {
      //code = 'analogRead( Port )';
      let code = 'analogRead( ' + eMod[3][0] + ' )';
      return [code, Arduino.ORDER_ATOMIC];
    }
  }

  return '';
};

/**************************************************
 * BLOCKS for DHT22 Module
 **************************************************/
Arduino['lcd-display-on-line'] = function(block){

  let aModName = block.getFieldValue("ModuleName");

  for (const eMod of Hardware.usedModules)
  {
    //if module type and name are the same with the block
    if ((eMod[0] == 'LCD20-I2C') && (eMod[1] == aModName))
    {
      //code = 'lcd.setCursor(0,LineNo);\n';
      //code += 'lcd.print( Message );\n';
      let code = eMod[2] + '.setCursor( 0, ' 
                + block.getFieldValue("LineNo") + ' );\n';
      code += eMod[2] + '.print( "' + block.getFieldValue("Message") + '" );\n';
      return code;
    }
  }

  return '';
};
