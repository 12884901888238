/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Generating JavaScript for dynamic variable blocks.
 */

import * as goog from '../../closure/goog/goog.js';
goog.declareModuleId('Blockly.Arduino.variablesDynamic');

import {arduinoGenerator as Arduino} from '../arduino.js';
import './variables.js';


// JavaScript is dynamically typed.
Arduino['variables_get_dynamic'] = Arduino['variables_get'];
Arduino['variables_set_dynamic'] = Arduino['variables_set'];
