import * as goog from '../../closure/goog/goog.js';
goog.declareModuleId('Blockly.Arduino.predefined');

import {arduinoGenerator as Arduino} from '../arduino.js';
import {Hardware} from '../../core/hardware.js';

Arduino['initialization'] = function(block) {
  
  for ( const iInclude of Hardware.includes_)
  {
    Arduino.addInclude(iInclude[0], iInclude[1]);
  }

//  code = localStorage.getItem("declarationCode");
//  if (code != "")
//    Arduino.addDeclaration("initBlock", code);

  for (const iDeclare of Hardware.declarations_)
  {
    Arduino.addDeclaration(iDeclare[0], iDeclare[1]);
  }

//  code = localStorage.getItem("setupCode");
//  if (code != "")
//    Arduino.addSetup("initBlock", code);

  for (const iSetup of Hardware.setups_)
  {
    Arduino.addSetup(iSetup[0], iSetup[1]);
  }
   
  return '';
};
