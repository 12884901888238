/**
 * @license Licensed under the Apache License, Version 2.0 (the "License"):
 *          http://www.apache.org/licenses/LICENSE-2.0
 */

/**
 * @fileoverview Generating Arduino code for variables blocks.
 */
import * as goog from '../../closure/goog/goog.js';
goog.declareModuleId('Blockly.Arduino.variables');

import {NameType} from '../../core/names.js';
import {arduinoGenerator as Arduino} from '../arduino.js';

/**
 * Code generator for variable (X) getter.
 * Arduino code: loop { X }
 * @param {Blockly.Block} block Block to generate the code from.
 * @return {array} Completed code with order of operation.
 */
Arduino['variables_get'] = function(block) {
  var code = Arduino.nameDB_.getName(block.getFieldValue('VAR'),
      NameType.VARIABLE);
  return [code, Arduino.ORDER_ATOMIC];
};

/**
 * Code generator for variable (X) setter (Y).
 * Arduino code: type X;
 *               loop { X = Y; }
 * @param {Blockly.Block} block Block to generate the code from.
 * @return {string} Completed code.
 */
Arduino['variables_set'] = function(block) {
  var argument0 = Arduino.valueToCode(block, 'VALUE',
      Arduino.ORDER_ASSIGNMENT) || '0';
  var varName = Arduino.nameDB_.getName(
      block.getFieldValue('VAR'), NameType.VARIABLE);
  return varName + ' = ' + argument0 + ';\n';
};

/**
 * Code generator for variable (X) casting (Y).
 * Arduino code: loop { (Y)X }
 * @param {Blockly.Block} block Block to generate the code from.
 * @return {array} Completed code with order of operation.
 */
Arduino['variables_set_type'] = function(block) {
  var argument0 = Arduino.valueToCode(block, 'VARIABLE_SETTYPE_INPUT',
      Arduino.ORDER_ASSIGNMENT) || '0';
  var varType = Arduino.getArduinoType_(
      Blockly.Types[block.getFieldValue('VARIABLE_SETTYPE_TYPE')]);
  var code = '(' + varType + ')(' + argument0 + ')';
  return [code, Arduino.ORDER_ATOMIC];
};
